
const getPrice = price => {
  if (price && typeof price === "string") {
    if (price.startsWith("$")) {
      price = price.substring(1)
    }
    price = price.replace(",", "")
    price = parseFloat(price)
  } else {
    price = 0
  }
  return price
}

module.exports.getPrice = getPrice;
