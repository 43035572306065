
const ContextStorage = (name) => {
  const persist = (state) => {
    if (state.private__context__key) {
      if (typeof window !== 'undefined') {
        localStorage.setItem(`${name}-${state.private__context__key}`, JSON.stringify(state));
      }
    }

    return state;
  }
  const restore = (initialState, contextKey) => {
    let newState = { ...initialState };
    if (contextKey !== undefined) {
      newState.private__context__key = contextKey;
      if (typeof window !== 'undefined') {
        const state = localStorage.getItem(`${name}-${contextKey}`)
        if (state) {
          newState = JSON.parse(state);
        }
      }
    }
    return newState;
  }
  return {
    persist,
    restore,
  };
}

export default ContextStorage;