// module.exports.WORDPRESS_URL = `https://rmsvstaging.wpengine.com`

module.exports = {
  REVISION_VER: 43,
  WORDPRESS_URL: process.env.WORDPRESS_URL || "https://simplur.wpengine.com",
  BRAND_FULLNAME: "Simplur, Inc.",
  BRAND_SHORTNAME: "Simplur",
  BRAND_PHONE: "303-704-9171",
  LIVESITE_URL: "https://www.simplur.com",
  PRODUCTION_URL: "https://www.simplur.com/",
  THEME_COLOR: "#4e4887",
  FIREBASE_APIKEY: "AIzaSyD2U_PM3pRJhtanaKd4BciJaAFctJDHVJ0",
  FIREBASE_AUTHDOMAIN: "simplur-live.firebaseapp.com",
  FIREBASE_DB_URL: "https://simplur-live.firebaseio.com",
  FIREBASE_PROJECT_ID: "simplur-live",
  FIREBASE_SENDER_ID: "1067617064822",
  NOCODEAPI_RFP_ID: "EXPKRYiZGDnjfYCb",
  NOCODEAPI_ATTENDANCE_ID: "",
  BRAND_ADDRESS: "Simplur, Inc., 275 Olive Street, Denver, CO, US",
  GTM_ID: "GTM-5XP27P6",
  HOMAGE_PAGE_ID: 7,
}
