import React, { useReducer } from "react"

import ContextStorage from "./ContextStorage"
import { getPrice } from "../utils/product"

const { persist, restore } = ContextStorage("Cart")
const CartContext = React.createContext({})

const initState = {
  items: [],
  subTotal: 0,
  totalValue: 0,
  version: 0,
  shippingCost: 0,
  taxValue: 0,
  currencySymbol: "$",
}

const calculateSubTotal = state => {
  const { items } = state
  const subTotal = items?.reduce(
    (acc, item) => acc + item.price * 100 * item.quantity,
    0
  )
  return subTotal / 100
}

const calculateTotal = state => {
  const subTotal = calculateSubTotal(state)
  return subTotal + state.shippingCost + state.taxValue
}

const addItem = (state, { product: newItem, qty = 1, variationId }) => {
  let { items } = state
  const i = items.findIndex(item => item.productId === newItem.productId)
  if (i > -1) {
    newItem.quantity = items[i].quantity + parseInt(qty)
    const newItems = [
      ...items.filter(item => item.productId !== newItem.productId),
      {
        ...newItem,
      },
    ]

    items = newItems
  } else {
    // newItem.price = getPrice(newItem.price);
    // newItem.displayPrice = newItem.price;
    // items = [...items, { quantity: parseInt(qty), ...newItem }]

    let price = 0
    let name = newItem.name
    let image = newItem.image
    if (newItem.type === "VARIABLE") {
      const variation = newItem.variations.nodes.find(
        v => v.variationId == variationId
      )
      price = getPrice(variation.price)
      name = variation.name
      image = variation.image
    } else {
      price = getPrice(newItem.price)
    }

    items = [
      ...items,
      {
        ...newItem,
        name: name || newItem.name,
        quantity: parseInt(qty),
        displayPrice: price,
        price,
        variationId: newItem.type === "VARIABLE" ? variationId : null,
        image: image || newItem.image,
      },
    ]
  }

  return items
}

const removeItem = (state, id) => {
  const { items } = state
  return items.filter(item => item.productId !== id)
}

const handleQuantity = (state, { id, qty }) => {
  let { items } = state

  if (qty > 0) {
    const i = items.findIndex(item => item.productId === id)
    if (i > -1) {
      items = [...items]
      items[i].quantity = qty
    }
  }

  return items
}

const updateItem = (state, payload) => {
  return [
    ...state.items.filter(item => item.productId !== payload.product.productId),
    payload.product,
  ]
}

const reducer = (state, action) => {
  let nextState
  switch (action.type) {
    case "ADD_TO_CART":
      nextState = {
        ...state,
        items: addItem(state, action.payload),
        version: state.version + 1,
      }
      break
    case "UPDATE_CART":
      nextState = {
        ...state,
        items: updateItem(state, action.payload),
        version: state.version + 1,
      }
      break
    case "REMOVE_FROM_CART":
      nextState = {
        ...state,
        items: removeItem(state, action.payload),
        version: state.version + 1,
      }
      break
    case "UPDATE_QUANTITY":
      nextState = {
        ...state,
        items: handleQuantity(state, action.payload),
        version: state.version + 1,
      }
      break
    case "SET_SHIPPING_COST":
      nextState = {
        ...state,
        shippingCost: action.payload,
        version: state.version + 1,
      }
      break
    case "SET_TAX":
      nextState = {
        ...state,
        taxValue: action.payload,
        version: state.version + 1,
      }
      break
    case "CLEAR_CART":
      nextState = {
        ...initState,
        private__context__key: action.payload,
      }
      break
    default:
      nextState = state
      break
  }

  nextState.subTotal = Number(calculateSubTotal(nextState).toFixed(2))
  nextState.totalValue = Number(calculateTotal(nextState).toFixed(2))
  return persist(nextState)
}

const CartContextProvider = ({ children, name }) => {
  const [state, dispatch] = useReducer(reducer, restore(initState, name))

  const actions = {
    addToCart: (product, qty, variationId) =>
      dispatch({ type: "ADD_TO_CART", payload: { product, qty, variationId } }),
    updateCart: product =>
      dispatch({ type: "UPDATE_CART", payload: { product } }),
    removeCartItem: id => dispatch({ type: "REMOVE_FROM_CART", payload: id }),
    updateQty: (id, qty) =>
      dispatch({ type: "UPDATE_QUANTITY", payload: { id, qty } }),
    setShippingCost: value =>
      dispatch({ type: "SET_SHIPPING_COST", payload: value }),
    setTax: value => dispatch({ type: "SET_TAX", payload: value }),
    reset: () => dispatch({ type: "CLEAR_CART", payload: name }),
  }

  const value = {
    actions,
    state,
    dispatch,
  }

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

const CartContextConsumer = CartContext.Consumer

export { CartContextProvider, CartContextConsumer, CartContext }
