import React from "react"
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  defaultDataIdFromObject,
} from "@apollo/client"
import { ApolloProvider } from "@apollo/react-hooks"
import "firebase/database"

import { CartContextProvider } from "./src/contexts/CartContext"
import { WORDPRESS_URL } from "./utils/constants"

import "./src/styles/style.scss"

export const wrapRootElement = ({ element }) => {
  /**
   * Middleware operation
   * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
   */
  const middleware = new ApolloLink((operation, forward) => {
    /**
     * If session data exist in local storage, set value as session header.
     */
    const session = localStorage.getItem("woo-session")
    if (session) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          "woocommerce-session": `Session ${session}`,
        },
      }))
    }

    return forward(operation)
  })

  /**
   * Afterware operation
   * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
   */
  const afterware = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      /**
       * Check for session header and update session in local storage accordingly.
       */
      const context = operation.getContext()
      const {
        response: { headers },
      } = context
      const session = headers.get("woocommerce-session")
      if (session) {
        // remove session data if session destroyed.
        if ("false" === session) {
          localStorage.removeItem("woo-session")
          // update session new data if changed.
        } else if (localStorage.getItem("woo-session") !== session) {
          localStorage.setItem("woo-session", session)
        }
      }

      return response
    })
  })

  const httpLink = createHttpLink({
    uri: WORDPRESS_URL + "/graphql",
  })

  // Apollo Caching configuration
  const cache = new InMemoryCache({
    dataIdFromObject: object => {
      // eslint-disable-next-line
      switch (object.__typename) {
        case "CartItem":
          return object.key
        default:
          return object.id || defaultDataIdFromObject(object)
      }
    },
  })

  const client = new ApolloClient({
    fetch,
    link: middleware.concat(afterware.concat(httpLink)),
    cache,
    clientState: {},
  })
  return (
    <ApolloProvider client={client}>
      <CartContextProvider name="product-card">{element}</CartContextProvider>
    </ApolloProvider>
  )
}

export const onClientEntry = () => {
  console.log("We've started!")
}

export const onRouteUpdate = function({ location }) {
  // Don't track while developing.
  if (typeof fbq === `function`) {
    window.fbq("track", "ViewContent")
  }
}

export const onServiceWorkerUpdateReady = () => {
  alert("New update available, Click okay to update")
  window.location.reload(true)
}
